import { Component } from "react";

class FunTrack extends Component{
        render(){
                return (
                        <>
                        <section class="" id="funtrack">
                                <div class="container">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                                <div class="feature_header text-center">
                                                        <h1 class="feature_title no-transform">Doprovodný program <br /> UniTrh</h1>
                                                        <h4 class="feature_sub">
                                                                <p>
                                                                        Součástí UnITu je i <b>veletrh IT firem</b>, které mohou kolemjdoucí navštívit a pobavit se s nimi o tom, jak IT svět funguje.
                                                                </p>
                                                                <p>
                                                                        Vedle firem najdete však spoustu méně seriózních stánků, kde se můžete něco dozvědět nebo na chviličku zabavit mezi přednáškami. V minulých ročnících to bylo např. stanoviště s oldschoolovými herními 
                                                                        automaty,  laser game nebo třeba s čokoládovou fontánou.
                                                                </p>
                                                                <p>
                                                                        Na konceptu ale stále pracujeme - nic ti neunikne, pokud budeš sledovat událost na <a href="https://fb.me/e/225PQLLeY"> Facebooku </a>.
                                                                </p>
                                                        </h4>

                                                        
                                                </div>
                                        </div>
                                </div>
                        </section>
                        </>
                )
        }
}
export default FunTrack;
import React, { Component } from "react";

import {spusteny_prihlasky} from "../../config";

class Application extends Component {
    render() {
        if (spusteny_prihlasky){
            return(
            <>
            <section class="section" id="application">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="feature_header text-center">
                                <p class="feature_sub">Přesvědčili jsme tě?</p>
                                <div>
                                    <a href="/application-1-single" class="btn btn-main featured btn-lg" style={{width:"600px"}}>Přihlásit se na IoT aplikace jednotlivci</a>
                                </div>
                                <div>
                                    <a href="/application-2-single" class="btn btn-main featured btn-lg" style={{width:"600px"}}>Přihlásit se na Webová aplikace jednotlivci</a>
                                </div>
                                <div>
                                    <a href="/application-1-team" class="btn btn-main featured btn-lg" style={{width:"600px"}}>Přihlásit se na IoT aplikace tým</a>
                                </div>
                                <div>
                                    <a href="/application-2-team" class="btn btn-main featured btn-lg" style={{width:"600px"}}>Přihlásit se na  Webová aplikace tým</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )
        }
        return(
            <>
            <section class="section" id="application">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="feature_header text-center">


                                <p class="feature_sub">Přihlášky nejsou spuštěny</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </> 
        
        );
    }
}

export default Application;
import React, { Component } from "react";

// hlavni partneri
import logo_abra from '../../images/Partneri/UnIT22/Abra-01.png';
import logo_bosch from '../../images/Partneri/UnIT22/Bosch-01.png';
import logo_comap from '../../images/Partneri/UnIT22/Unknown-01.png';

import logo_baader from '../../images/Partneri/UnIT22/Baader-01.png';
import logo_artin from '../../images/Partneri/UnIT22/ArtinWeb-01.png';
import logo_qminers  from '../../images/Partneri/UnIT22/QMiners-01.png';
import logo_gemsystem from '../../images/Partneri/UnIT22/GemSystem-01.png';
import logo_oksystem from '../../images/Partneri/UnIT22/OkSystem-01.png';
import logo_startupjobs from '../../images/Partneri/UnIT22/StartupJobs-01.png';
import logo_unicorn from '../../images/Partneri/UnIT22/UnicornWeb-01.png';



class Partners extends Component {
    render() {
        return(
            <>
            <section class="section" id="partneri">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="feature_header text-center">
                                <h3 class="feature_title"><strong>Partneři soutěže</strong></h3>
                                <h4 class="feature_sub"> </h4>
                                <div class="divider"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="clearfix"></div>
                <br />
                <br />
                <div class="container">
                    <div class="row">
                        <div class="col-xs-16 col-md-10 col-md-push-1">
                            {/* HLAVNÍ PARTNEŘI */}
                            <div class="text-center col-xs-12 col-md-10 col-md-push-1" style={{paddingBottom: "30px"}}>
                                Hlavní partneři
                                <ul class="portfolio-wrap" id="portfolio_items1" style={{paddingTop: "20px"}}>
                                    <li class="col-xs-6 single-portfolio identity">
                                        <figure>
                                            <a href="https://bosch.cz" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_bosch} alt="Bosch logo"
                                                     class="img-responsive pull-left"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-6 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.abra.eu/kariera/?utm_source=fb&utm_medium=HRinzerat&utm_campaign=Best_UnIT" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_abra} alt="ABRA logo"
                                                     class="img-responsive pull-right"/>
                                            </a>
                                        </figure>
                                    </li>
                                    {/* <li class="col-xs-5 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.comap-control.com/" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_comap} alt="ComAp logo"
                                                     class="img-responsive pull-right"/>
                                            </a>
                                        </figure>
                                    </li> */}
                                </ul>
                            </div>
                        
                            {/* PARTNEŘI */}
                            <div class="clearfix"></div>
                            {
                            <div class="text-center col-xs-1 col-md-10 col-md-push-1">
                                Další partneři
                                <ul class="portfolio-wrap" id="portfolio_items2" style={{paddingTop: "20px"}}>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://qminers.cz" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_qminers}
                                                     alt="qMiners logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.gemsystem.cz/" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_gemsystem}
                                                     alt="GemSystem logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://artin.cz" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_artin}
                                                     alt="Artin logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.bcpraha.cz/" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_baader}
                                                     alt="Baader logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.unicorn.com/cs" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_unicorn}
                                                     alt="Unicorn logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>

                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://www.oksystem.com/cz" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_oksystem}
                                                     alt="OkSystem logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                    <li class="col-xs-4 single-portfolio identity">
                                        <figure>
                                            <a href="https://startupjobs.cz" target="_blank">
                                                <img style={{height:"94%;", width:"94%;"}} src={logo_startupjobs}
                                                     alt="StartupJobs logo"
                                                     class="img-responsive"/>
                                            </a>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

export default Partners;
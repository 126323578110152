import React, { Component } from "react";

import logo_bosch from '../../images/Partneri/UnIT22/Bosch-01.png';
import logo_abra from '../../images/Partneri/UnIT22/Abra-01.png';
import logo_comap from '../../images/Partneri/UnIT22/Unknown-01.png';

import Counter from "./Counter";
import Prizes from "./prizes";
import Faq from "./faq";

class Category extends Component {
    render() {
        return(
            <>
            <section class="section" id="zadani">
                <div class="container">
                <div class="divider"></div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="feature_header text-center">
                                <h1 class="feature_title no-transform"><b>Soutěžní kategorie a zadání</b></h1>
                                <h4>Tradičně je na UnITu na výběr ze tří kategorií. A stejně je tomu i letos.</h4>
                            </div>
                        </div>
 
                        <div class="text-center pricing">
                        <div class="col-md-0 col-xs-12 col-sm-12"> </div>
                            <div class="col-md-4 col-xs-12 col-sm-18" id="kategorie-abra">
                                <div class="single_table">
                                    <div class="plan_wraper"></div>
                                    <ul>
                                        <li class="plan"><h3>IoT aplikace</h3><span style={{opacity: "0;"}}>Chytrá inventura</span>
                                        </li>
                                        <li class="partner">
                                            <div class="row">
                                                <div class="col-xs-8 col-xs-push-2">
                                                    <figure>
                                                        <a href="#" target="_blank" data-toggle="modal" data-target="#oFirmeOne">
                                                            <img class="img-responsive"  src={logo_abra} alt="Logo ABRA Software" />
                                                        </a>
                                                    </figure>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="#" data-toggle="modal" data-target="#modalCatOne">Více o kategorii</a></li>    
                                        <li><a href="#" data-toggle="modal" data-target="#oFirmeOne">Více o firmě ABRA Software</a></li>
                                        <li><strong>Přihlášky budou již brzy otevřeny</strong></li>
                                    </ul>
                                </div>
                            </div>
                    
                            <div class="col-md-4 col-xs-15 col-sm-12" id="kategorie-bosch">
                                <div class="single_table">
                                    <div class="plan_wraper"></div>
                                    <ul>
                                        <li class="plan"><h3>Webová aplikace</h3><span> Aplikace pro řešení zásobování výrobních linek</span>
                                        </li>
                                        <li class="partner">
                                            <div class="row">
                                                <div class="col-xs-8 col-xs-push-2">
                                                    <figure>
                                                        <a href="#" target="_blank" data-toggle="modal" data-target="#oFirmeTwo">
                                                            <img class="img-responsive" src={logo_bosch} alt="Logo Bosch" />
                                                        </a>
                                                    </figure>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="#" data-toggle="modal" data-target="#modalCatTwo">Více o kategorii</a></li>
                                        <li><a href="#" data-toggle="modal" data-target="#oFirmeTwo">Více o firmě Bosch</a></li>

                                        <li><strong>Přihlášky budou již brzy otevřeny</strong></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-15 col-sm-12" id="kategorie-abra">
                                <div class="single_table">
                                    <div class="plan_wraper"></div>
                                    <ul>
                                        <li class="plan"><h3>A již brzy...</h3><span style={{opacity: "0;"}}>S partnerem stále komunikujeme</span>
                                        </li>
                                        <li class="partner">
                                            <div class="row">
                                                <div class="col-xs-8 col-xs-push-2">
                                                <figure>
                                                    <a href="#"  target="_blank" data-toggle="modal" data-target="#oFirmeThree">
                                                        <img class="img-responsive" src={logo_comap} alt="Logo utajeno" />
                                                    </a>
                                                </figure>
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li><a href="#" data-toggle="modal" data-target="#modalCatThree">Více o kategorii</a></li>
                                        <li><a href="#" data-toggle="modal" data-target="#oFirmeThree">Více o firmě ComAp</a></li> */}

                                        <li>Více o kategorii doplníme</li>
                                        <li>?</li>
                                        <li><strong>Přihlášky budou již brzy otevřeny</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="feature_header text-center">
                        <h4>
                            Hledáš kolegu do týmu? Zkus napsat na naší Facebookovou
                            <strong><a href="https://www.facebook.com/groups/3141426076114075"> skupinu</a></strong>
                            .
                        </h4>
                        <br />
                        <br />
                    </div>
                    <div class="divider"></div>
                </div>
                    
                <div class="container">
                    <br />
                    <Prizes /> 
                    <br />
                    <br />
                    <br />
                    <Faq />
                </div>
            </section>
                                        
            <Counter />
            

{/* PŘEDZADÁNÍ */}
            <div id="modalCatOne" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">ABRA Software - Chytrá inventura</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                <br/>
                                <p><b>O co půjde </b><br />
                                    V Abře potřebujeme vyrobit webovou aplikaci, která zjednoduší lidem skladové inventury. K dispozici bude na telefonu nebo tabletu, 
                                    někdo s ní bude pracovat také na čtečce čárových kódu, ostatní využijí třeba jen foťák na telefonu. Veškerý hardware Ti zapůjčíme, 
                                    ať můžeš všechno otestovat v praxi. Připravíme pro Tebe i virtuální sklad se zbožím, kde si v praxi vyzkoušíš, jak jednotlivé kódy 
                                    naskenovat a udělat si takovou malou inventuru na vlastní kůži. K dispozici Ti bude i hotové API a přehled metod, které budeš pro 
                                    komunikaci potřebovat. Stejně tak jsme Ti k dispozici i my. Kdykoli s námi můžeš cokoli konzultovat. 
                                </p>
                                <p>
                                    Technologie: JavaScript, HTML5, (je na tobě, jestli to bude SPA, nebo něco, co má výstup ze serveru)

                                </p>
                                <p>
                                    <b>Pojď prodat svojí práci</b> <br />
                                    Nyní máš jedinečnou příležitost dostat svoji aplikaci mezi naše zákazníky. Nehledě na to, jestli je to tvůj první ročník nebo si 
                                    zasloužíš odznak UnIT veterán.

                                </p>
                                <p>
                                    <b>Jak?</b> <br />
                                    Pomůžeme Ti s propagací, aby se o Tvé appce zákazníci dozvěděli. Přidáme ji do našeho app storu a dáme Ti možnost ji i prodávat, 
                                    takže kromě zkušeností na tom můžeš také něco vydělat. Potkáš se s reálným feedbackem od lidí a aplikaci můžeš dál rozvíjet ve 
                                    spolupráci s naším týmem. To posouvá soutěž na trochu jiný level, co říkáš?
                                </p>
                            </i>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalCatTwo" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Bosch - Aplikace pro řešení zásobování výrobních linek</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                <br/>
                                <p>
                                    Základem efektivní štíhlé výroby je včasná a spolehlivá logistika výrobního materiálu mezi výrobními linkami a sklady.
                                </p>
                                <p>
                                    Úkolem bude naprogramovat webovou aplikaci, která umožní správu logistických vláčků, jejich zastávek a materiálu, 
                                    který mezi zastávkami převážejí. Pro jednotlivé entity bude vytvořen CRUD s přihlédnutím na základní uživatelská 
                                    oprávnění aplikace.
                                </p>
                                <p>
                                    Technologie zvolte dle vlastního výběru podle následujících kritérií:
                                    <br />
                                    &nbsp; - přístup k aplikaci pomocí webového rozhraní
                                    <br />
                                    &nbsp; - správné zobrazení aplikace na různých zařízeních (responzivita)
                                </p>
                            </i>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="modalCatThree" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Předzadání Team design</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                Předzadání firmy ComAp.

                            </i>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>

{/* O FIRMÁCH */}
            <div id="oFirmeOne" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">ABRA Software</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                <br/>
                                <p>
                                    Společnost ABRA Software začala jako start up dvou kamarádů. Ti jsou stále její součástí a je to znát. I když zaměstnáváme přes 200 lidí 
                                    a kromě České republiky působíme také na Slovensku a ve Švýcarsku, pořád u nás vládne přátelský duch. Už 30 let vyvíjíme komplexní ERP systém, 
                                    to znamená obrovské množství příležitostí učit se něco nového. 
                                    </p>
                                <p>
                                    Máme také cloudovou appku pro menší firmy, v Brně provozujeme e-commerce centrum a děláme weby i mobilní aplikace na míru. 
                                    </p>
                                <p>
                                    Na našem softwaru si zakládají známé značky jako je třeba Bonami, Liftago, PrusaResearch, Lekarna.cz nebo Profimed. Baví nás 
                                    jejich nápady a inovace, jak lidem usnadnit & zpříjemnit život. Jsme jejich spojencem na cestě k užitečnosti. 
                                    </p>
                                <p>
                                    Naše filosofie staví na hodnotách a baťovských principech. Podívej se, jak firemní strategii vnímají 
                                    <a href="https://www.facebook.com/ABRASoftware/videos/682124556480067"> naši ajťáci</a>. 
                                    </p>
                                <p>
                                    Hodnoty, vztahy, strategie, produktivita a pracovní prostředí jsou pro nás důležité – i proto jsme letos získali ocenění 
                                    <a href="https://www.abra.eu/abra-software-patri-k-nejlepe-rizenym-firmam-ceska-za-rok-2021/"> Czech Best Managed Companies </a>
                                    od společnosti Deloitte.
                                    </p>
                                <p>
                                    Držíme palce a těšíme se na další příležitost, kdy se potkat! 
                                    </p>
                                <p>
                                    Tým ABRA Software

                                </p>
                            </i>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>
                
            <div id="oFirmeTwo" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Bosch</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                <br/>
                                <p>
                                    Společnost Robert Bosch v Českých Budějovicích byla založena 1. května 1992 jako společný podnik stuttgartské společnosti 
                                    Robert Bosch GmbH a Motoru Jikov, a. s. V roce 1995 se společnost Bosch stala jediným vlastníkem společnosti v Českých Budějovicích.
                                </p>
                                <p>
                                    Pro novou společnost byl kompletně vystavěn nový závod s nejmodernějším vybavením a infrastrukturou na globální úrovni, 
                                    s vlastním oddělením vývoje a výzkumu včetně zkušebny pro dlouhodobé zkoušky.
                                </p>
                                <p>
                                    Zatím poslední a a velmi důležitou investicí se stalo vývojové a technologické centrum otevřené v září 2019. 
                                    Do samotné stavby budovy a vybavení laboratoří firma investovala na 850 milionů korun. 600 vývojářů se tu 
                                    zaměřuje na aplikovaný vývoj automobilových komponentů pro zákazníky z celého světa.
                                </p>
                                <p>
                                    Kariéra v Boschi? <a href="https://www.bosch.cz/kariera/">Více zde</a>
                                </p>
                                
                            </i>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="oFirmeThree" class="modal fade" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">ComAp</h4>
                        </div>
                        <div class="modal-body">
                            <i>
                                <br/>
                                <p>
                                    Text firmy ComAp
                                </p>
                            </i>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>
            

            

            
            </>
        );
    }
}

export default Category;
import React, { Component } from "react";


class Faq extends Component{
        render(){
                return(
                        <>
                        <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="feature_header text-center">
                                        <h3 class="feature_title no-transform"><strong>FAQ</strong></h3>
                                </div>
                                </div>

                        </div>
                        <div class="row">

                                <div class="col-xs-12 col-sm-10 col-sm-push-1 col-md-8 col-md-push-2">

                                <h4>Pro koho je soutěž určena?</h4>
                                <p>Účast na soutěži je <strong>zdarma</strong> a soutěžícím se může stát každý student vysoké školy
                                        nezávisle na ročníku.</p>

                                <h4>Jak bude soutěž probíhat?</h4>
                                <p>Letos bude soutěž probíhat offline v dejvickém kampusu ČVUT. O přesném způsobu průběhu soutěže </p>
                                <p>Vás včas informuje náš zkušený <a href="#organisers" class="page-scroll">tým organizátorů</a>.
                                </p>

                                <h4>Proč se na soutěž přihlásit?</h4>

                                <p> Máš možnost se seznámit s reálným problémem firmy z praxe, vyzkoušet své schopnost tvořit a 
                                komunikovat. Krom toho můžeš porovnat síly se spolužáky a navíc vyhrát spoustu super cen.
                                </p>

                                <h4>Kdy bude zpřístupněno zadání?</h4>

                                <p>Hrubé zadání soutěžních kategorií naleznete už teď v sekci 
                                        <a href="#zadani" class="page-scroll"> zadání</a>. Aby měli všichni stejné podmínky, tak se přesné zadání dozvíte až v den soutěže.
                                </p>

                                </div>
                                
                        </div>
                        </>
                )
        }
}

export default Faq;